import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/Layouts/layout';
import SEO from '../components/seo';

const SitemapPage = () => {
	const intl = useIntl();
	return (
		<Layout header={<FormattedMessage id='footer.sitemap' />}>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'footer.sitemap' })} />
			<Container>
				<Row>
					<Col md={12}>
						<ul className='sitemapList'>
							<li>
								<Link to='/'>
									<FormattedMessage id='nav.home' />
								</Link>
							</li>

							<li className='title'>
								<Link to='/about/'>
									<FormattedMessage id='nav.about' />
								</Link>
								<ul>
									<li>
										<Link to='/about/our-role/'>
											<FormattedMessage id='nav.ourRole' />
										</Link>
									</li>
									<li>
										<Link to='/about/governance/'>
											<FormattedMessage id='nav.governance' />
										</Link>
										<ul>
											<li>
												<Link to='/about/governance/compensation-disclosure'>
													<FormattedMessage id='nav.compensation' />
												</Link>
											</li>
										</ul>
									</li>

									<li>
										<Link to='/about/leadership/'>
											<FormattedMessage id='nav.leadership' />
										</Link>
									</li>

									<li>
										<Link to='/about/board-of-directors/'>
											<FormattedMessage id='nav.board' />
										</Link>
									</li>

									<li>
										<Link to='/about/research-and-education-program/'>
											<FormattedMessage id='nav.research' />
										</Link>
									</li>

									<li>
										<Link to='/about/consumer-advisory-council/'>
											<FormattedMessage id='nav.cac' />
										</Link>
									</li>

									<li>
										<Link to='/about/industry-advisory-council/'>
											<FormattedMessage id='nav.iac' />
										</Link>
									</li>

									<li>
										<Link to='/about/discipline-and-appeals-committees/'>
											<FormattedMessage id='daCommittee.headerI' />
										</Link>
									</li>
								</ul>
							</li>

							<li className='title'>
								<span>
									<FormattedMessage id='nav.licensingComp' />
								</span>
								<ul>
									<li>
										<Link to='/licensing-compliance/licensing/'>
											<FormattedMessage id='nav.licensing' />
										</Link>
									</li>

									<li>
										<Link to='/licensing-compliance/file-a-complaint/'>
											<FormattedMessage id='complaints.title' />
										</Link>

										<ul>
											<li>
												<Link to='/licensing-compliance/file-a-complaint/'>
													<FormattedMessage id='nav.fileComplaint' />
												</Link>
											</li>
										</ul>
									</li>

									<li>
										<Link to='/licensing-compliance/directives-advisories/'>
											<FormattedMessage id='nav.directives' />
										</Link>
									</li>

									<li className='title'>
										<span>
											<FormattedMessage id='nav.complaintsProcess' />
										</span>
										<ul>
											<li>
												<Link to='/licensing-compliance/complaints-process/'>
													<FormattedMessage id='complaintsProcess.page01' />
												</Link>
											</li>
											<li>
												<Link to='/licensing-compliance/how-to-file-a-complaint/'>
													<FormattedMessage id='complaintsProcess.page02' />
												</Link>
											</li>
											<li>
												<Link to='/licensing-compliance/possible-courses-of-action/'>
													<FormattedMessage id='complaintsProcess.page03' />
												</Link>
											</li>
										</ul>
									</li>
									<li>
										<Link to='/licensing-compliance/regulatory-activites-enforcement/'>
											<FormattedMessage id='nav.regActivites' />
										</Link>
										<ul>
											{/* <li>
                        <Link to='/licensing-compliance/regulatory-activites-enforcement/charges/'>
                          <FormattedMessage id='nav.charges' />
                        </Link>
                      </li>
                      <li>
                        <Link to='/licensing-compliance/regulatory-activites-enforcement/convictions/'>
                          <FormattedMessage id='nav.convictions' />
                        </Link>
                      </li> */}
											<li>
												<Link to='/licensing-compliance/regulatory-activites-enforcement/notices-and-orders/'>
													<FormattedMessage id='nav.notices' />
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li className='title'>
								<Link to='/news-and-events/'>
									<FormattedMessage id='nav.news' />
								</Link>

								<ul>
									<li className='title'>
										<Link to='/news-and-events/backgrounders/'>
											<FormattedMessage id='nav.backgrounders' />
										</Link>
									</li>
									<li className='title'>
										<Link to='/news-and-events/events/'>
											<FormattedMessage id='nav.events' />
										</Link>
									</li>
									<li>
										<Link to='/news-and-events/media-inquiries/'>
											<FormattedMessage id='nav.mediaInquiries' />
										</Link>
									</li>
									<li>
										<Link to='/news-and-events/news-releases/'>
											<FormattedMessage id='nav.newsReleases' />
										</Link>
									</li>
								</ul>
							</li>

							<li className='title'>
								<Link to='/new-home-buyer/dashboard/'>
									<FormattedMessage id='buyerNav.dashboard' />
								</Link>
								<ul>
									<li className='title'>
										<span>
											<FormattedMessage id='buyerNav.steps' />
										</span>
										<ul>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-1/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 1: ${intl.formatMessage({
														id: 'buyerNav.step1'
													})}`}
												</Link>
											</li>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-2/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 2: ${intl.formatMessage({
														id: 'buyerNav.step2'
													})}`}
												</Link>
											</li>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-3/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 3: ${intl.formatMessage({
														id: 'buyerNav.step3'
													})}`}
												</Link>
											</li>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-4/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 4: ${intl.formatMessage({
														id: 'buyerNav.step4'
													})}`}
												</Link>
											</li>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-5/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 5: ${intl.formatMessage({
														id: 'buyerNav.step5'
													})}`}
												</Link>
											</li>
											<li>
												<Link to='/new-home-buyer/steps-in-home-buyers-journey/step-6/'>
													{`${intl.formatMessage({
														id: 'nav.step'
													})} 6: ${intl.formatMessage({
														id: 'buyerNav.step6'
													})}`}
												</Link>
											</li>
										</ul>
									</li>
									<li>
										<Link to='/new-home-buyer/before-buying-a-preconstruction-condo/'>
											<FormattedMessage id='buyerNav.preConCondo' />
										</Link>
									</li>
									<li>
										<Link to='/new-home-buyer/expectations-for-your-builder/'>
											<FormattedMessage id='buyerNav.expectations' />
										</Link>
									</li>
									<li>
										<Link to='/new-home-buyer/working-with-your-builder/'>
											<FormattedMessage id='buyerNav.workingWith' />
										</Link>
									</li>
									<li>
										<Link to='/new-home-buyer/newsletter/'>
											<FormattedMessage id='newsletter.headerA' />
										</Link>
									</li>
								</ul>
							</li>

							<li className='title'>
								<Link to='/builder-vendor/dashboard/'>
									<FormattedMessage id='builderNav.dashboard' />
								</Link>
								<ul>
									<li className='title'>
										<span>
											<FormattedMessage id='builderNav.become' />
										</span>
										<ul>
											<li>
												<Link to='/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'>
													<FormattedMessage id='builderNav.step1' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/become-a-licensed-builder/before-you-apply/'>
													<FormattedMessage id='builderNav.step2' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/become-a-licensed-builder/applying-for-a-licence/'>
													<FormattedMessage id='builderNav.step3' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'>
													<FormattedMessage id='builderNav.step4' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/become-a-licensed-builder/after-licensing/'>
													<FormattedMessage id='builderNav.step5' />
												</Link>
											</li>
										</ul>
									</li>

									<li>
										<span>
											<FormattedMessage id='builderNav.complaints' />
										</span>
										<ul>
											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/complaints/'>
													<FormattedMessage id='builderNav.complaints1' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions/'>
													<FormattedMessage id='builderComplaints4.headerAalt' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/administrative-penalty/'>
													<FormattedMessage id='adminPenalties.header' />
												</Link>
											</li>

											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'>
													<FormattedMessage id='builderNav.complaints2' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/compliance-enforcement/'>
													<FormattedMessage id='builderNav.complaints3' />
												</Link>
											</li>
											<li>
												<Link to='/builder-vendor/complaints-compliance-enforcement/code-of-ethics/'>
													<FormattedMessage id='daCommittee.headerB' />
												</Link>
											</li>
										</ul>
									</li>

									<li>
										<Link to='/builder-vendor/faq/'>
											<FormattedMessage id='footer.faq' />
										</Link>
									</li>

									<li>
										<Link to='/builder-vendor/new-application-checklist/'>
											<FormattedMessage id='newAppChecklist.pageHeader' />
										</Link>
									</li>
									<li>
										<Link to='/builder-vendor/renewal-application-checklist/'>
											<FormattedMessage id='renewAppChecklist.pageHeader' />
										</Link>
									</li>
								</ul>
							</li>

							<li lang='en'>
								<Link to='/blog/'>HCRA Blog: The Home Front</Link>
							</li>

							<li>
								<Link to='/terms-of-use/'>
									<FormattedMessage id='footer.terms' />
								</Link>
							</li>

							<li>
								<Link to='/glossary/'>
									<FormattedMessage id='footer.glossary' />
								</Link>
							</li>

							<li>
								<a
									href='https://hcraontario.bamboohr.com/jobs/?source=aWQ9Ng=='
									// href='https://hcraontario.ca/job-postings.html'
									aria-label={intl.formatMessage({
										id: 'footer.careers'
									})}
								>
									<FormattedMessage id='footer.careers' />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default SitemapPage;
